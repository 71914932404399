import Vue from "vue";
import Vuex from "vuex";
import { Country, Locale, VAHA_TYPE_BY_COUNTRY } from "@/lib/settings";
import { loadLanguageAsync } from "../../translation/i18n-setup";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: Locale.DE,
    country: Country.DE,
    selectedProductIndex: 0,
    selectedVaha: VAHA_TYPE_BY_COUNTRY.DE[0],
    availableProducts: VAHA_TYPE_BY_COUNTRY.DE,
    referralCode: "",
    loadingReferralValidation: true,
    loadingLocation: true,
    isReferralCodeValid: false,
    showBasket: false,
  },
  mutations: {
    changeLanguage(state, value) {
      state.locale = value;
    },
    setCountry: (state, country: Country) => {
      state.country = country;

      if (country === Country.DE) state.selectedProductIndex = 0; // reset to first

      state.availableProducts = VAHA_TYPE_BY_COUNTRY[country];

      // selected product is at selectedProductIndex in country
      state.selectedVaha =
        VAHA_TYPE_BY_COUNTRY[country][state.selectedProductIndex];
    },
    setSelectedProductIndex: (state, index: number) => {
      state.selectedProductIndex = index;

      // selected product is at selectedProductIndex in country
      state.selectedVaha =
        VAHA_TYPE_BY_COUNTRY[state.country][state.selectedProductIndex];
    },
    setReferralCode: (state, code: string) => {
      state.referralCode = code;
    },
    setLoadingReferralValidation: (state, value: boolean) => {
      state.loadingReferralValidation = value;
    },
    setLoadingLocation: (state, value: boolean) => {
      state.loadingLocation = value;
    },
    setReferralCodeValid: (state, value: boolean) => {
      state.isReferralCodeValid = value;
    },
    showBasket: (state, value: boolean) => {
      state.showBasket = value;
    },
  },
  actions: {
    async changeLanguage(context, lang) {
      await loadLanguageAsync(lang);
      context.commit("changeLanguage", lang);
    },
    setCountry: (context, country: Country) => {
      context.commit("setCountry", country);
    },
    setSelectedProductIndex: (context, index: number) => {
      context.commit("setSelectedProductIndex", index);
    },
    setReferralCode: (context, code: string) => {
      context.commit("setReferralCode", code);
    },
    setLoadingReferralValidation: (context, value: boolean) => {
      context.commit("setLoadingReferralValidation", value);
    },
    setLoadingLocation: (context, value: boolean) => {
      context.commit("setLoadingLocation", value);
    },
    setReferralCodeValid: (context, value: boolean) => {
      context.commit("setReferralCodeValid", value);
    },
    showBasket: (context, value: boolean) => {
      context.commit("showBasket", value);
    },
  },
  modules: {},
});
