











































import { Component, Vue } from "vue-property-decorator";

@Component({
  computed: {
    selectedVaha() {
      return this.$store.state.selectedVaha;
    },
  },
})
export default class Intro extends Vue {
  memberName = this.$route.params.memberName || "";
}
