




















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MainFeatures extends Vue {
  selectedIndex = 0

  features: { title: string; text: string }[] = []

  beforeMount(): void {
    this.initTexts()
  }

  initTexts(): void {
    for (let i = 1; i <= 4; i++) {
      this.features.push({
        title: `REFERRAL.MAIN_FEATURES.FEATURE${i}.TITLE`,
        text: `REFERRAL.MAIN_FEATURES.FEATURE${i}.TXT`,
      })
    }
  }

  select(index: number): void {
    this.selectedIndex = index
  }

  isSelected(index: number): boolean {
    return this.selectedIndex === index
  }
}
