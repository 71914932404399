







































































import { Component, Vue } from 'vue-property-decorator'
import { trackEvent } from '@/lib/utils'
import { VahaType } from '@/lib/settings'

@Component({
  computed: {
    availableProducts() {
      return this.$store.state.availableProducts
    },
  },
})
export default class Products extends Vue {
  async selectProduct(index: number) {
    await this.$store.dispatch('setSelectedProductIndex', index)
    await this.trackEvent(index)
    this.showBasket()
  }

  async trackEvent(index: number) {
    const productType = this.$store.state.availableProducts[index].type
    await trackEvent(this.$gtag, 'Checkout', 'addToCart', productType === VahaType.S ? 'buy_vaha_s' : 'buy_vaha_x')
  }

  showBasket(): void {
    this.$store.dispatch('showBasket', true)
  }
}
