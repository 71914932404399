











import { Component, Vue } from 'vue-property-decorator'
import { trackEvent } from '@/lib/utils'

@Component({
  computed: {
    selectedVaha() {
      return this.$store.state.selectedVaha
    },
  },
})
export default class Outro extends Vue {
  async showBasket() {
    await trackEvent(this.$gtag, 'Checkout', 'addToCart', 'continue_main')
    await this.$store.dispatch('showBasket', true)
  }
}
