import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initAnalytics } from "@/lib/utils";
import { fetchDefaultLocale } from "../translation/i18n-setup";

Vue.config.productionTip = false;

initAnalytics();

fetchDefaultLocale().then((i18n: any) => {
  new Vue({
    router,
    store,
    // @ts-ignore
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
});
