

























import { Component, Vue } from "vue-property-decorator";
import { trackEvent } from "@/lib/utils";

@Component({
  computed: {
    selectedVaha() {
      return this.$store.state.selectedVaha;
    },
  },
})
export default class Footer extends Vue {
  memberName = this.$route.params.memberName || "";

  async showBasket() {
    await trackEvent(this.$gtag, "Checkout", "addToCart", "continue_footer");
    await this.$store.dispatch('showBasket', true)
  }
}
