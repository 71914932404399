import { Country } from "@/lib/settings";
import axios from "axios";
import { Vue } from "vue-property-decorator";
import VueGtag from "vue-gtag";

export function getStoreURL(storeUrl: string, referralCode: string): string {
  return storeUrl.replace("{code}", referralCode);
}

const LOCATION_API = "https://api.ipregistry.co/?key=99g56ta64ar67p";
export async function getCountry(): Promise<Country | undefined> {
  return axios
    .get(LOCATION_API)
    .then((response: any) =>
      Promise.resolve(Country[response.data.location.country.code])
    )
    .catch((e) => Promise.resolve(undefined));
}

const REFERRAL_CODE_API = `${process.env.VUE_APP_API_HOST}/checkout/api/v2/referral_codes/{referralCode}/validate`;
export async function validateReferralCode(code: string): Promise<boolean> {
  if (!isCodeFormatValid(code)) return false;

  return axios
    .get(REFERRAL_CODE_API.replace("{referralCode}", code))
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

const REFERRAL_CODE_PREFIX = "VAHA-";
function isCodeFormatValid(code: string): boolean {
  return !!code && code.indexOf(REFERRAL_CODE_PREFIX) === 0;
}

export function initAnalytics() {
  Vue.use(VueGtag, {
    config: { id: "UA-143994469-1" },
  });
}

export async function trackEvent(gtag, category, action, label) {
  await gtag.event(action, { event_category: category, event_label: label });
}
