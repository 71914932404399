












































import { Component, Vue } from 'vue-property-decorator'

@Component({
  computed: {
    selectedVaha() {
      return this.$store.state.selectedVaha
    },
    availableProducts() {
      return this.$store.state.availableProducts
    },
  },
})
export default class Financing extends Vue {
  setSelectedProductIndex(index: number): void {
    this.$store.dispatch('setSelectedProductIndex', index)
  }
}
