export enum VahaType {
  X = "X",
  S = "S",
}

export enum Country {
  DE = "DE",
  GB = "GB",
}

export enum Locale {
  EN = "en-uk",
  DE = "de-de",
}

type Settings = {
  type: VahaType;
  size: string;
  weight: string;
  screenSize: string;
  storeUrl: string;
  monthlyStartPrice: string;
  totalPrice: string;
  financingService: string;
  membershipDiscount: string;
  financingOptions: { months: number; value: string }[];
  membershipStartingPrice: string;
};

const VAHA_S_COMMON_SETTINGS = {
  type: VahaType.S,
  size: "150 X 50 X 4.2 CM",
  weight: "27 KG",
  screenSize: "32",
};

const VAHA_X_COMMON_SETTINGS = {
  type: VahaType.X,
  size: "170 X 62 X 4.2 CM",
  weight: "45 KG",
  screenSize: "43",
};

export const VAHA_TYPE_BY_COUNTRY: { GB: Settings[]; DE: Settings[] } = {
  GB: [
    {
      ...VAHA_X_COMMON_SETTINGS,
      storeUrl: process.env.VUE_APP_UK_SHOP_X,
      monthlyStartPrice: "£29.49",
      totalPrice: "£1,950.00",
      financingService: "KLARNA",
      membershipDiscount: "£39",
      financingOptions: [
        { months: 39, value: "£50.00" },
        { months: 24, value: "£81.25" },
        { months: 12, value: "£162.50" },
        { months: 1, value: "£1,950.00" },
      ],
      membershipStartingPrice: "£39",
    },
    {
      ...VAHA_S_COMMON_SETTINGS,
      storeUrl: process.env.VUE_APP_UK_SHOP_S,
      monthlyStartPrice: "£29.49",
      totalPrice: "£1,150.00",
      financingService: "KLARNA",
      membershipDiscount: "£39",
      financingOptions: [
        { months: 39, value: "£29.49" },
        { months: 24, value: "£47.92" },
        { months: 12, value: "£95.83" },
        { months: 1, value: "£1,150.00" },
      ],
      membershipStartingPrice: "£39",
    },
  ],
  DE: [
    {
      ...VAHA_X_COMMON_SETTINGS,
      storeUrl: process.env.VUE_APP_DE_SHOP_X,
      monthlyStartPrice: "30 €",
      totalPrice: "2.268 €",
      financingService: "ZAB_1_12_24_39",
      membershipDiscount: "39 €",
      financingOptions: [
        { months: 39, value: "58 €" },
        { months: 24, value: "94 €" },
        { months: 12, value: "189 €" },
        { months: 1, value: "2.268 €" },
      ],
      membershipStartingPrice: "39 €",
    },
    {
      ...VAHA_S_COMMON_SETTINGS,
      storeUrl: process.env.VUE_APP_DE_SHOP_S,
      monthlyStartPrice: "30 €",
      totalPrice: "1.395 €",
      financingService: "ZAB_1_12_24_39",
      membershipDiscount: "39 €",
      financingOptions: [
        { months: 39, value: "36 €" },
        { months: 24, value: "58 €" },
        { months: 12, value: "116 €" },
        { months: 1, value: "1.395 €" },
      ],
      membershipStartingPrice: "39 €",
    },
  ],
};
