



























import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Intro from "@/components/Intro.vue";
import MainFeatures from "@/components/MainFeatures.vue";
import Classes from "@/components/Classes.vue";
import Products from "@/components/Products.vue";
import Financing from "@/components/Financing.vue";
import Outro from "@/components/Outro.vue";
import Footer from "@/components/Footer.vue";
import Spinner from "@/components/Spinner.vue";
import { Country, Locale } from "@/lib/settings";
import { getCountry, getStoreURL, validateReferralCode } from "@/lib/utils";

const MAIN_WEBSITE = "https://vaha.com";

@Component({
  components: {
    Header,
    Intro,
    MainFeatures,
    Classes,
    Products,
    Financing,
    Outro,
    Footer,
    Spinner,
  },
  computed: {
    loadingReferral() {
      return this.$store.state.loadingReferralValidation;
    },
    loadingLocation() {
      return this.$store.state.loadingLocation;
    },
    isBasketOpened() {
      return this.$store.state.showBasket;
    },
    selectedProductIndex() {
      return this.$store.state.selectedProductIndex;
    },
    lang() {
      return this.$store.state.locale;
    },
    region() {
      return this.$store.state.locale.split("-")[1];
    },
    currencyCode() {
      return this.$store.state.country === Country.GB ? "GBP" : "EUR";
    },
    vahaLinkX() {
      const product = this.$store.state.availableProducts[0];
      if (product)
        return getStoreURL(product.storeUrl, this.$store.state.referralCode);
    },
    vahaLinkS() {
      const product = this.$store.state.availableProducts[1];
      if (product)
        return getStoreURL(product.storeUrl, this.$store.state.referralCode);
    },
  },
})
export default class Referral extends Vue {
  beforeMount(): void {
    this.setupReferralCode();
    this.setupLocation();
  }

  async setupReferralCode(): Promise<void> {
    this.$store.dispatch("setLoadingReferralValidation", true);

    const code = this.$route.params.referralCode;

    if (!(await this.isCodeValid(code))) {
      return this.redirectToMainWebsite();
    }

    this.storeReferralCode(code);

    this.$store.dispatch("setLoadingReferralValidation", false);
  }

  isCodeValid(code: string): Promise<boolean> {
    return validateReferralCode(code);
  }

  storeReferralCode(code: string): void {
    this.$store.dispatch("setReferralCodeValid", true);
    this.$store.dispatch("setReferralCode", code);
  }

  redirectToMainWebsite(): void {
    this.$store.dispatch("setReferralCodeValid", false);
    window.location.href = MAIN_WEBSITE;
  }

  async setupLocation(): Promise<void> {
    this.$store.dispatch("setLoadingLocation", true);

    if (
      process.env.VUE_APP_ALLOW_COUNTRY_QUERY_PARAM === "true" &&
      this.$route.query.country
    ) {
      this.setupManualCountry();
      return;
    }

    const country: Country | undefined = await getCountry();

    this.setLocale(country);
    this.setCountry(country);

    this.$store.dispatch("setLoadingLocation", false);
  }

  setupManualCountry(): void {
    const queryCountry =
      this.$route.query.country === "gb" ? Country.GB : Country.DE;
    this.setLocale(queryCountry);
    this.setCountry(Country[queryCountry]);
    this.$store.dispatch("setLoadingLocation", false);
  }

  setCountry(country: Country | undefined): void {
    this.$store.dispatch("setCountry", country || Country.DE);
  }

  setLocale(country: Country | undefined): void {
    let locale = Locale.DE;
    if (country === Country.GB) locale = Locale.EN;

    const html = document.querySelector("html");
    if (html) html.setAttribute("lang", locale);

    this.$store.dispatch("changeLanguage", locale);
  }

  onBasketClosed(): void {
    this.$store.dispatch("showBasket", false);
  }

  onBasketProductChanged(event): void {
    this.$store.dispatch("setSelectedProductIndex", event.detail[0]);
  }
}
