import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Referral from "@/views/Referral.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:referralCode?/:memberName?",
    name: "Referral",
    component: Referral,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
