import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import { Locale } from "@/lib/settings";

Vue.use(VueI18n);

let i18n;

export const fetchDefaultLocale = async () => {
  const pathLang = `${window.location.origin}/lang/${Locale.DE}.json`;
  try {
    const response = await axios.get(pathLang, {
      headers: {
        "content-type": "application/json",
      },
    });

    i18n = new VueI18n({
      locale: Locale.DE, // set locale
      fallbackLocale: Locale.EN,
      messages: { en: response.data }, // set locale messages
    });

    return i18n;
  } catch (error) {
    console.log("Error fetching default locale, ", error);
    return null;
  }
};

const loadedLanguages = [Locale.DE]; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const pathLang = `${window.location.origin}/lang/${lang}.json`;
  // If the language hasn't been loaded yet

  axios.get(pathLang, {
    headers: {
      "content-type": "application/json",
    },
  }).then((response) => {
    i18n.setLocaleMessage(lang, response.data);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}
